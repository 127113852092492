.about-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: -10rem;


  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
    margin-top: -35rem;
  }
}

.about-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #666;
    margin-bottom: 20px;
  }
}

.about-videos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  .video {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    &.video1 {
      margin-top: 0;
      grid-row: 1;
    }

    &.video2 {
      margin-top: 50px;
      grid-row: 1;
    }

    &.video3 {
      margin-top: 0;
      grid-row: 2;
    }

    &.video4 {
      margin-top: 50px;
      grid-row: 2;
    }
  }
}
