.hero {
  position: relative;
  width: 100%;
  height: 100vh;

  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    object-fit: cover;
    z-index: 0; // Background video
  }

  .hero-content {
    position: relative;
    z-index: 1; // Content above the video
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    color: #fff;
  }

  .hero-logo {
    width: 200px;  // Increased by 60px
    height: 200px; // Increased by 60px
    margin-top: -20rem;
    object-fit: contain; // Preserve aspect ratio
  }

  .hero-button-container {
    margin-top: 100px; // Space above the button, adjust as needed
  }

  .hero-button {
    background-color: #007bff; // Button color
    color: #333333; // Text color
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: #EFF764; // Hover background color
      color: #FEFEFE; // Hover text color
    }
  }

  // Media query for mobile devices
  @media (max-width: 768px) {
    .hero-video {
      position: relative; // Change position to relative
      width: 100vw; // Set width to viewport width
      height: auto; // Auto height to maintain aspect ratio
      max-height: 80vh; // Limit max height to viewport height
      object-fit: contain; // Ensure the whole video is visible
      top: 0; // Reset top to ensure the video is centered vertically
      left: 0; // Reset left to ensure the video is centered horizontally
    }

    .hero-logo {
      display: none;
    }

    .hero-button-container {
      display: none;
    }

    .hero-button {
      display: none;
    }
  }
}
