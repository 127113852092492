.product-catalog {
  padding: 20px;
  background-color: #f5f5f5;
  
  h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .product-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    text-align: center;
    
    .product-image {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    
    .product-details {
      padding: 15px;
      
      .product-name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #333;
      }
      
      .product-specifications {
        list-style: none;
        padding: 0;
        margin: 0;
        
        .specification-item {
          font-size: 14px;
          color: #666;
          margin-bottom: 5px;
        }
      }
      
      .product-price {
        font-size: 16px;
        font-weight: bold;
        color: #e74c3c;
        margin-bottom: 10px;
      }
      
      .toggle-description-button {
        background-color: #3498db;
        color: #fff;
        border: none;
        padding: 10px 15px;
        cursor: pointer;
        font-size: 14px;
        margin-bottom: 10px;
        border-radius: 5px;
        
        &:hover {
          background-color: #2980b9;
        }
      }
      
      .product-description-expanded {
        font-size: 14px;
        color: #666;
        margin-top: 10px;
      }
    }
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    margin: 20px 0; // Adjust spacing as needed
  }
  
  .view-catalog-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 25px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    font-weight: bold;

    &:hover {
      background-color: #0056b3;
    }
  }
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal {
  position: relative;
  background: #fff;
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
}

.modal-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #e74c3c;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  
  &:hover {
    background: #c0392b;
  }
}
