.footer {
  padding: 2rem 1rem;
  background-color: #333333; // White background
  color: #FEFEFE; // Crna boja teksta
  text-align: center;

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .footer-links {
    display: flex;
    justify-content: center; // Centriranje linkova
    gap: 2rem; // Razmak između linkova

    a {
      color: #FEFEFE; // Crna boja linkova
      text-decoration: none;
      font-weight: bold;

      &:hover {
        color: #52AEFE; // Hover boja linkova (tamno plava)
      }
    }
  }

  .footer-social {
    display: flex;
    justify-content: center; // Centriranje ikonica
    gap: 1.5rem; // Razmak između ikonica

    a {
      color: #FEFEFE; // Crna boja ikonica
      font-size: 1.5rem; // Veličina ikonica

      &:hover {
        color: #52AEFE; // Hover boja ikonica (tamno plava)
      }
    }
  }

  .footer-rights {
    margin-top: 1.5rem;

    p {
      margin: 0;
      font-size: 0.875rem; // Manja veličina fonta
      color: #FEFEFE; // Crna boja teksta za autorska prava
    }
  }
}
